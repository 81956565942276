'use client';

import { unstable_getScrollbarSize as getScrollbarSize } from '@mui/utils';

export const getDocumentScrollbarSize = () => {
    if (typeof window === 'undefined') {
        return 0;
    }
    return getScrollbarSize(window);
};
