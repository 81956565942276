'use client';

import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { prefixCmsUrl } from '@/lib/prefixCmsUrl';
import { CmsBlogPostSummary } from '@/services/cms/models/CmsBlogPostSummary';
import { gtmTriggerEvent } from '@/services/googleTagManager';
import { CardDescription } from './components/CardDescription';
import { CardImage } from './components/CardImage';
import { CardTitle } from './components/CardTitle';

export interface BlogPostCardProps {
    blogPost: CmsBlogPostSummary;
}

export const BlogPostCard = React.forwardRef<HTMLDivElement, BlogPostCardProps>(({ blogPost }, ref) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();

    return (
        <Card
            ref={ref}
            elevation={0}
            onClick={() =>
                gtmTriggerEvent('interaction', {
                    interaction_source: 'Blog post card',
                    interaction_action: 'click',
                    interaction_label: blogPost.title,
                })
            }
        >
            {blogPost.featuredImage ? <CardImage src={prefixCmsUrl(blogPost.featuredImage.link)} alt={blogPost.featuredImage.title || blogPost.title || ''} /> : null}
            <CardContent sx={{ px: 0 }}>
                <CardTitle sx={{ height: '60px' }}>{blogPost.title}</CardTitle>
                <CardDescription>
                    <CmsHtmlContent html={blogPost.summary} plain={true} />
                </CardDescription>
            </CardContent>
            <CardActions sx={{ px: 0 }}>
                <Link variant="body2" sx={{ fontWeight: 'bold' }} href={addSiteLinkPrefix(blogPost.url)}>
                    Read more
                </Link>
            </CardActions>
        </Card>
    );
});
BlogPostCard.displayName = 'BlogPostCard';
